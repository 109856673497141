.container {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  max-height: 190px;
  align-self: center;
  padding-top: 70px;
  display: flex;
  width: 120px;
  animation: fadeIn 0.75s ease-out;
  transition: 1s;
  cursor: pointer;
}

.header svg {
  pointer-events: none;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  margin-top: 20px;
  margin-bottom: 40px;
  animation: fadeIn 0.75s ease-out;
}

.content > * {
  animation: fadeIn 0.75s ease-out;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.25s;
  animation: fadeIn 0.75s ease-out;
  max-height: 200px;
}

.infoButton {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--active-color);
  border-radius: 12px;
  width: 60%;
  max-width: 800px;
  height: 60px;
  border: none;
  outline: none;
  color: var(--foreground-color);
  font-size: 16px;
  transition: 0.25s;
}

@media screen and (min-width: 600px) {
  .infoButton {
    width: 100%;
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
